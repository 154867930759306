import { createRoot } from "react-dom/client";
import { App } from "./App";
import React from "react";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Provider as RBProvider, ErrorBoundary } from "@rollbar/react";
import { rollbarConfig } from "./utils/rollbar";
import { ddInit } from "./utils/datadog";

// Initialise datadog
ddInit();

const container = document.getElementById("app") as HTMLElement;
const root = createRoot(container);

root.render(
  <RBProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </RBProvider>
);
