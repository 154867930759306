import React, { useEffect } from "react";
import {
  getSessionRequest,
} from "../../../api";
import {
  setTmpSessionId,
} from "../../../store/reducers/session";
import { ErrorDetail, SessionRoute, SessionSectionContext, SessionState } from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { extensionSendMessage } from "../../../utils/extensionCom";
import { useOutletContext, useNavigate } from "react-router-dom";


export interface SessionStartContext extends SessionSectionContext {
  trackerRef: React.RefObject<HTMLDivElement>;
  panelist: string;
}
export const SessionStart = () => {
  const {
    broker,
    dispatch,
    panelist,
    trackerRef,
    errorHandle
  } = useOutletContext<SessionStartContext>();
  const navigate = useNavigate();

  const initBroker = async () => {
    let div = trackerRef.current;
    if (!div) throw ErrorFormatter.getError(ErrorClass.TRACKER_DIV);
    await broker.init(30000, div);
  }


  // TODO: Check if we want camera on startup
  // useEffect(() => {
  //   const startUp = async () => {
  //     try {
  //       let permRes = await navigator.permissions.query({ name: "camera" });
  //       if (permRes.state === "granted") {
  //         await initBroker();
  //       }
  //     } catch(e) {
  //       console.warn(e)
  //     }
  //   }
  //   startUp();
  // }, []);

  const cont = async () => {
    try {
      let m: PanelMessages.ExternalMessage = {
        type: PanelMessages.ExternalMessageType.CheckPermissions
      };
      let perms: boolean = await extensionSendMessage(m);
      if (!perms) {
        throw ErrorClass.CAMERA_PERMISSIONS
      }
      let res = await getSessionRequest(panelist);
      dispatch(setTmpSessionId(res.tmpSessionId));
      // TODO: HANDLE GET SESSION ID ERROR
      if (!broker.state.initialised) {
        await initBroker();
      }
      navigate(SessionRoute.POSITION);
    } catch (e) {
      if (e === ErrorClass.EXTENSION_COMMS_FAIL) {
        window.location.href = "/install";
      } else if (e === ErrorClass.CAMERA_PERMISSIONS) {
        let detail: ErrorDetail = { 
          fmt: ErrorFormatter.getError(e),
          action: () => (window.location.reload()),
          route: SessionRoute.ERROR
        }
        errorHandle(detail);
      } else {
        errorHandle(e);
      }
    }
  };
  return (
    <>
      <button
        onClick={
          cont
        }
        className="mb-10 bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Start a 10 minute Research session
      </button>
      <p className="mb-10">Note: We do not record pictures or video of you</p>
    </>
  );
};
