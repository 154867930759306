import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from "./root";
import Home from "./pages/home";
import Install from "./pages/install";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import { SessionStart } from "./components/session/sections/start";
import { SessionRoute } from "./components/session/session";
import { SessionPosition } from "./components/session/sections/position";
import { SessionCalibration } from "./components/session/sections/calibration";
import { SessionValidation } from "./components/session/sections/validation";
import { SessionExternal } from "./components/session/sections/external";
import { SessionReturn } from "./components/session/sections/return";
import { SessionCompletion } from "./components/session/sections/complete";
import { SessionError } from "./components/session/sections/error";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root></Root>,
    errorElement: <h1>Error</h1>, // TODO: Error Element,
    children: [
      {
        path: "/",
        element: <Home />,
        children: [
          {
            path: SessionRoute.START,
            element: <SessionStart />
          },
          {
            path: SessionRoute.POSITION,
            element: <SessionPosition />,
          },
          {
            path: SessionRoute.CALIBRATION,
            element: <SessionCalibration />
          },
          {
            path: SessionRoute.VALIDATION,
            element: <SessionValidation />
          },
          {
            path: SessionRoute.EXTERNAL,
            element: <SessionExternal />
          },
          {
            path: SessionRoute.RETURN,
            element: <SessionReturn />
          },
          {
            path: SessionRoute.COMPLETE,
            element: <SessionCompletion />
          },
          {
            path: SessionRoute.ERROR,
            element: <SessionError />
          }
        ]
      },
      {
        path: "/install",
        element: <Install />,
      },
      {
        path: "/privacy-policy",
        element: <Privacy />,
      },
      {
        path: "/terms-and-conditions",
        element: <Terms />,
      },
    ]
  },
]);

export function App() {
  return <RouterProvider router={router} />
}