import React, { useRef, useState } from "react";
import { LRExtensionBroker } from "@lumen-developer/lumen-common-js/esm/brokers";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { AppDispatch } from "../../store/store";
import { SessionStart, SessionStartContext } from "./sections/start";
import { SessionPosition, SessionPositionContext } from "./sections/position";
import { SessionCalibration, SessionCalibrationContext } from "./sections/calibration";
import { SessionValidation } from "./sections/validation";
import { SessionExternal, SessionExternalContext } from "./sections/external";
import { SessionReturn } from "./sections/return";
import { SessionCompletion } from "./sections/complete";
import ErrorFormatter, { ErrorFormat, GENERIC_SAFE_FMT } from "../../utils/errorFormat";
import { SessionError, SessionErrorContext } from "./sections/error";
import { VALIDATION_SCORE_CEIL } from "../../utils/constants";
import { Outlet, useLocation, Navigate, matchRoutes, useNavigate } from "react-router-dom";

// TODO: ERRORS

export enum SessionState {
  START,
  POSITION,
  CALIBRATION,
  VALIDATION,
  EXTERNAL,
  RETURN,
  COMPLETE,
  ERROR,
}

export enum SessionRoute {
  START = "/",
  POSITION = "/session/position",
  CALIBRATION = "/session/calibration",
  VALIDATION = "/session/validation",
  EXTERNAL = "/session/external",
  RETURN = "/session/return",
  COMPLETE = "/session/complete",
  ERROR = "/session/error",
}

export interface SessionSectionContext {
  broker: LRExtensionBroker;
  dispatch: AppDispatch;
  errorHandle: (detail: ErrorDetail | string) => void;
}

export interface SessionValidationContext extends SessionSectionContext {
  validationRef: React.RefObject<HTMLDivElement>;
  trackerRef: React.RefObject<HTMLDivElement>;
  validationSessionDetails: {
    panelist: string;
    panelistId: number;
    tmpSessionId: string;
    calibrationTime: number;
    accessCode: string;
  };
  setValidationStatus?: (score: number) => void;
}

export interface ErrorDetail {
  fmt: ErrorFormat;
  action: () => void;
  route: SessionRoute;
}

const Session = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const panelist = useAppSelector((state) => state.auth.panelist);
  const panelistId = useAppSelector((state) => state.auth.panelistId);
  const accessCode = useAppSelector((state) => state.auth.accessCode);
  const calibrationTime = useAppSelector(
    (state) => state.session.calibrationTime
  );
  const tmpSessionId = useAppSelector((state) => state.session.tmpSessionId);
  const dispatch = useAppDispatch();

  const [sessionState, setSessionState] = useState(SessionState.START);
  const [status, setStatus] = useState({ position: 0, validation: 0 });
  const [errorDetail, setErrorDetail] = useState<ErrorDetail>({
    fmt: GENERIC_SAFE_FMT,
    action: () => (window.location.href = "/"),
    route: SessionRoute.ERROR,
  });
  const [broker, _setBroker] = useState<LRExtensionBroker>(
    new LRExtensionBroker()
  );

  const trackerRef = useRef<HTMLDivElement>(null);
  const calibrationRef = useRef<HTMLDivElement>(null);
  const validationRef = useRef<HTMLDivElement>(null);

  const routeObjectFactory = (arr: SessionRoute[]): {path: SessionRoute}[] => {
    return arr.map(r => {
      return {
        path: r
      }
    })
  }
  const directAccessMatch = matchRoutes(
    routeObjectFactory([SessionRoute.START, SessionRoute.RETURN, SessionRoute.ERROR]), location
  );
  const showScoreTableMatch = matchRoutes(
    routeObjectFactory([SessionRoute.POSITION, SessionRoute.CALIBRATION, SessionRoute.VALIDATION, SessionRoute.EXTERNAL, SessionRoute.ERROR]), location
  );
  const showCameraTrackerMatch = matchRoutes(
    routeObjectFactory([SessionRoute.START, SessionRoute.POSITION, SessionRoute.CALIBRATION, SessionRoute.VALIDATION, SessionRoute.EXTERNAL, SessionRoute.ERROR]), location
  );

  const errorHandle = (e: ErrorDetail | ErrorFormat | any) => {
    console.log("error handle called")
    if (e.fmt && e.action && e.route) {
      setErrorDetail(e);
      navigate(e.route);
    } else if (e.logLevel) {
      const detail = {
        fmt: e,
        action: () => (window.location.reload()),
        route: SessionRoute.ERROR,
      }
      setErrorDetail(detail);
      navigate(detail.route);
    } else {
      const detail = {
        fmt: ErrorFormatter.formatError(e),
        action: () => (window.location.reload()),
        route: SessionRoute.ERROR,
      }
      console.log("error handle generic", e)
      setErrorDetail(detail);
      navigate(detail.route);
    }
  };

  // redirect to start if broker is uninitialised on sections that expect it
  if (!directAccessMatch) {
    if (!broker.state.initialised) return(<Navigate to={SessionRoute.START} replace={true} />)
  }

  const sessionContext = (): SessionSectionContext | SessionStartContext | SessionPositionContext | SessionCalibrationContext | SessionValidationContext | SessionExternalContext | SessionErrorContext | undefined => {
    switch (location.pathname) {
      case SessionRoute.START:
        return {
          broker,
            dispatch,
            panelist,
            trackerRef,
            errorHandle
        };
      case SessionRoute.POSITION:
        return {
          broker,
          dispatch,
          setPositionStatus: (score: number) => {
            setStatus({ ...status, position: score });
          },
          errorHandle
        };
      case SessionRoute.CALIBRATION:
        return {
            dispatch,
            broker,
            calibrationRef,
            trackerRef,
            errorHandle
          }
      case SessionRoute.VALIDATION:
        return {
            broker,
            dispatch,
            validationSessionDetails: {
              panelist,
              panelistId,
              tmpSessionId,
              calibrationTime,
              accessCode,
            },
            validationRef,
            trackerRef,
            errorHandle,
            setValidationStatus: (score: number) => {
              setStatus({ ...status, validation: score });
            }
          }
      case SessionRoute.EXTERNAL:
        return {
            broker,
            accessCode,
            panelist,
            panelistId,
            tmpSessionId,
            dispatch,
            errorHandle
          }
      case SessionRoute.RETURN:
        return {
            broker,
            dispatch,
            validationSessionDetails: {
              panelist,
              panelistId,
              tmpSessionId,
              calibrationTime,
              accessCode,
            },
            validationRef,
            trackerRef,
            errorHandle
          }
      case SessionRoute.COMPLETE:
        return {
            broker,
            dispatch,
            errorHandle,
          }
      case SessionRoute.ERROR:
        return {
            broker,
            dispatch,
            errorHandle,
            errorDetail
          }
      default:
        return
    }
  };

  return (
    <>
      <div
        className={`w-full h-full fixed bg-white top-0 left-0; ${
          location.pathname === SessionRoute.ERROR ? "hidden" : "visible"
        }`}
        style={{ display: "none" }}
        ref={calibrationRef}
      ></div>
      <div
        className={`w-full h-full fixed bg-white top-0 left-0; ${
          location.pathname === SessionRoute.ERROR ? "hidden" : "visible"
        }`}
        style={{ display: "none" }}
        ref={validationRef}
      ></div>
      {/* <div className="lg:w-1/2 lg:p-10">{sessionSwitch()}</div> */}
      <div className="lg:w-1/2 lg:p-10">{<Outlet context={sessionContext()} />}</div>
      <div className="lg:w-1/2 lg:p-10">
        <div
          ref={trackerRef}
          className={`w-full h-2/3 border-2 border-gray-400 rounded ${
            showCameraTrackerMatch
              ? "visible"
              : "hidden"
          }`}
        ></div>
        <div
          className={`${
            !!showScoreTableMatch
              ? "visible"
              : "hidden"
          }`}
        >
          <p className="mt-6">Your scores:</p>
          <table className="score-table table-auto border-spacing-2 border border-slate-300 w-full">
            <tbody>
              <tr className="border-b border-b-slate-300 py-4">
                <td className="font-semibold text-slate-500">Type</td>
                <td className="font-semibold text-slate-500">Target</td>
                <td className="font-semibold text-slate-500">Your Score</td>
                <td className="font-semibold text-slate-500">Status</td>
              </tr>
              <tr className="py-4">
                <td>Positioning</td>
                <td>80</td>
                <td>{status.position} / 100</td>
                <td>
                  {status.position >= 80 ? (
                    <CheckCircleIcon className="h-8 p-1 flex text-[#4BB543] focus:outline-none" />
                  ) : (
                    <ExclamationCircleIcon className="h-8 p-1 flex text-[#eed202] focus:outline-none" />
                  )}
                </td>
              </tr>
              <tr className={`py-4 ${status.validation === 0 ? "hidden" : "visible"}`}>
                <td>Validation</td>
                <td>{`< ${VALIDATION_SCORE_CEIL}`}</td>
                <td>~{Math.floor(status.validation)}</td>
                <td>
                  {status.validation <= VALIDATION_SCORE_CEIL ? (
                    <CheckCircleIcon className="h-8 p-1 flex text-[#4BB543] focus:outline-none" />
                  ) : (
                    <ExclamationCircleIcon className="h-8 p-1 flex text-[#eed202] focus:outline-none" />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Session;
