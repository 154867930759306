import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { ErrorClass } from "./errorFormat";

export const extensionSendMessage = (m: PanelMessages.ExternalMessage): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      chrome.runtime.sendMessage(process.env.EXTENSION_ID, m, (r: any) => {
        if (chrome.runtime.lastError) reject(ErrorClass.EXTENSION_COMMS_FAIL)
        resolve(r);
      });
    } catch(e) {
      reject(ErrorClass.EXTENSION_COMMS_FAIL);
    }
  });
};
