import React from "react";

const Install = () => {
  return (
    <div className="bg-gray-50 h-3/5 lg:h-full p-8 lg:px-16 lg:col-span-2 flex justify-center border-l border-t">
      <div className="lg:w-1/2 lg:p-10">
        <h1 className="font-semibold mb-10 text-2xl">Download the Browser Extension</h1>
        <button onClick={() => {
            window.open(("https://chromewebstore.google.com/detail/the-focus-project/" + process.env.EXTENSION_ID), '_blank')?.focus();
        }} className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-10">
          Download
        </button>
        <p className="font-semibold mb-10">Overview</p>
        <p className="mb-10">Why join:<br />The Focus Project is a chance to earn points that can be exchanged for rewards.</p>
        <p className="mb-10">What you wil be doing:<br />Participating in a market research panel that aims to understand what people pay attention too while use the internet. We do this via eye tracking by predicting where you look by using the camera of your device.</p>
        <p>How does it work:<br />Each time you have a web session or complete a task using our app we capture two sets of data:</p>
        <ol className="mb-10 list-decimal list-inside">
            <li>Eye tracking data by turning on your camera and asking you to do a calibration task</li>
            <li>Information about the websites you visit</li>
        </ol>
        <p className="mb-10">Upon successful completion of a sessiont or task you will earn points!</p>
      </div>
    </div>
  );
};

export default Install;
