import React from "react";
import { SessionSectionContext } from "../session";
import { useOutletContext } from "react-router-dom";

export const SessionCompletion = () => {
  const {
    broker,
    dispatch,
    errorHandle
  } = useOutletContext<SessionSectionContext>();
  return (
    <>
      <p className="font-semibold mb-10">Session complete</p>
      <p className="mb-10">You earned 1000 points = £1</p>
      <button
        onClick={
          () => window.location.href = "/"
        }
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Start another session
      </button>
    </>
  );
};
