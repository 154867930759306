import React, { useEffect } from "react";
import { ErrorDetail, SessionSectionContext } from "../session";
import { useOutletContext } from "react-router-dom";
import { useRollbar } from "@rollbar/react";
import { LOGLEVEL } from "../../../utils/errorFormat";

export interface SessionErrorContext extends SessionSectionContext {
    errorDetail: ErrorDetail;
  }
export const SessionError = () => {
  const {
    broker,
    dispatch,
    errorDetail
  } = useOutletContext<SessionErrorContext>();

  const rollbar = useRollbar();

  useEffect(() => {
    switch(errorDetail.fmt.logLevel) {
      case LOGLEVEL.ERROR:
        rollbar.error(errorDetail.fmt.internalMessage)
        break;
      case LOGLEVEL.WARN:
        rollbar.warn(errorDetail.fmt.internalMessage)
        break;
      case LOGLEVEL.INFO:
        rollbar.info(errorDetail.fmt.internalMessage)
      case LOGLEVEL.SILENT:
        break;
    }
  }, [])

  return (
    <>
      <p className="font-semibold mb-10">Error!</p>
      <p className="mb-10 text-red-500">{errorDetail.fmt.safeMessage}</p>
      <p className="mb-10">Please use the button below to try again.</p>
      <button
        onClick={
            errorDetail.action
        }
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Continue
      </button>
    </>
  );
};
