import {
  TrackerController,
  GroundTruthTrackerController,
  MobileConfigV2,
  EventManager,
  ConfigManager,
  GazeCalibratorConfig,
} from "@lumen-developer/rni-webcam-js";
import {
  WasmImportedModuleConfig,
  WasmImportedModuleFactory,
} from "@lumen-developer/rni-webcam-wasm";
import { BrokerRollbar } from "../utils/rollbar";
import LRBroker from "./LRBroker";
import { DynamicConfig, INIT_RESOLVE_EVENT } from "./types";

export default class LRExtensionBroker extends LRBroker {
  trackersController: TrackerController;
  eventManager: EventManager;
  nextFrameHandler: { remove: Function };
  landmarkDetectorInitializedHandler: { remove: Function };
  landmarksEventHandler: { remove: Function };
  calibrationEventHandler: { remove: Function };
  validationEventHandler: { remove: Function };
  gazeEventHandler: { remove: Function };
  errorEventHandler: { remove: Function };
  timeoutHandler: NodeJS.Timeout;
  frameRateArr: number[];
  errPassthrough: Function;
  state: { initialised: boolean };

  constructor() {
    super();
    this.state = {
      initialised: false,
    };
  }

  /**
   * initialises broker
   * @param {array} customEventListener - Array of objects containing event and callback
   */
  init = (
    timeout: number,
    trackerDiv: HTMLDivElement = null,
    dynConfig: DynamicConfig = { resolveEvent: INIT_RESOLVE_EVENT.FIRST_FRAME }
  ) => {
    return new Promise(async (resolve: (_: null) => void, reject) => {
      if (this.state.initialised) {
        return;
      }

      /* This sets the timeout for the function and has to be removed when the
       * function is complete
       */
      this.startTimer(
        () => {
          if (!!this.nextFrameHandler) {
            this.nextFrameHandler.remove();
          }
        },
        () => {
          throw "init timed out";
        },
        timeout
      );

      let config = new (MobileConfigV2 as any)() as ConfigManager;
      config.frameReader.framerate.ideal = 30;
      config.frameReader.framerate.max = 30;
      config.landmarkDetector.worker.useWebWorkers = false;
      if (!Array.isArray(config.gazeValidator.calibrator)) {
        config.gazeValidator.calibrator.view.margin.y = 0.1;
        config.gazeValidator.calibrator.view.margin.x = 0.1;
      } else {
        config.gazeValidator.calibrator.forEach((c) => {
          c.view.margin.y = 0.1;
          c.view.margin.x = 0.1;
        });
      }
      config.gazeDetector.george.factory = new WasmImportedModuleFactory();
      config.landmarkDetector.george.factory = new WasmImportedModuleFactory();
      this.trackersController = new TrackerController(config);
      this.eventManager = this.trackersController.eventManager();
      if (!!trackerDiv) {
        this.trackersController.setVideoDiv(trackerDiv);
      }

      this.errorEventHandler = this.eventManager.subscribe(
        "onError",
        (e: any) => {
          // TODO: handle specific errors here
          BrokerRollbar.error(e.name, e);
          if (this.errPassthrough) {
            this.errPassthrough(e);
          }
        }
      );

      if (
        !dynConfig ||
        dynConfig.resolveEvent === INIT_RESOLVE_EVENT.FIRST_FRAME
      ) {
        // return callback
        this.nextFrameHandler = this.eventManager.subscribe(
          "onNextFrame",
          () => {
            clearTimeout(this.timeoutHandler);
            this.nextFrameHandler.remove();
            this.state = {
              initialised: true,
            };
            resolve(null);
          }
        );
      }

      Promise.all([
        this.trackersController.initFrameReader(true),
        this.trackersController.initLandmarkDetector(),
        this.trackersController.initGazeDetector(true),
      ])
        .then(() => {
          if (dynConfig.resolveEvent === INIT_RESOLVE_EVENT.PROMISE_ALL) {
            this.state = {
              initialised: true,
            };
            clearTimeout(this.timeoutHandler);
            resolve(null);
          }
        })
        .catch((err) => {
          if (this.nextFrameHandler) this.nextFrameHandler.remove();
          reject(err);
        });
    });
  };
}
