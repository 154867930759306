import { datadogRum } from '@datadog/browser-rum';
const packageInfo = require('../../package.json');

export const ddInit = () => {
    datadogRum.init({
        applicationId: '1011ca9e-02f7-4e2c-a6b2-d4764094296c',
        clientToken: 'pub90df682a122688e1bfe93d562ffba31d',
        site: 'datadoghq.eu',
        service: 'focusproject-web',
        env: process.env.ENVIRONMENT ? process.env.ENVIRONMENT : "release",
        version: `${packageInfo.version}-web`,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
}
