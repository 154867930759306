{
  "name": "focus-project-web",
  "version": "1.0.11",
  "private": true,
  "source": "src/index.html",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "start": "cross-env NODE_ENV=local parcel --no-cache -p 3000",
    "build": "parcel build",
    "link:common": "npm link @lumen-developer/lumen-common-js",
    "unlink:common": "npm unlink --no-save @lumen-developer/lumen-common-js"
  },
  "devDependencies": {
    "@lumen-developer/lumen-common-js": "^2.1.6",
    "@types/chrome": "^0.0.268",
    "@types/node": "^22.4.2",
    "@types/react": "^18.3.3",
    "@types/react-dom": "^18.3.0",
    "@types/react-router-dom": "^5.3.3",
    "axios": "^1.7.4",
    "buffer": "^6.0.3",
    "cross-env": "^7.0.3",
    "crypto-browserify": "^3.12.0",
    "events": "^3.3.0",
    "parcel": "^2.12.0",
    "postcss": "^8.4.39",
    "process": "^0.11.10",
    "stream-browserify": "^3.0.0",
    "string_decoder": "^1.3.0",
    "tailwindcss": "^3.4.4",
    "vm-browserify": "^1.1.2"
  },
  "dependencies": {
    "@datadog/browser-rum": "^5.28.1",
    "@headlessui/react": "^2.1.2",
    "@heroicons/react": "^2.1.4",
    "@reduxjs/toolkit": "^2.2.6",
    "@rollbar/react": "^0.12.0-beta",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "react-redux": "^9.1.2",
    "react-router-dom": "^6.24.1",
    "redux-persist": "^6.0.0",
    "rollbar": "^2.26.4"
  },
  "@parcel/resolver-default": {
    "packageExports": true
  },
  "optionalDependencies": {
    "@parcel/watcher-linux-x64-glibc": "^2.4.2-alpha.0"
  }
}
